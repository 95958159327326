<footer class="text-lg-start">
  <div *ngIf="showTopFooter" class="top-footer">
    <!-- Grid container -->
    <div class=" container p-4">     
    </div>
  </div>
  <!-- Grid container -->

  <div class="bottom-footer p-1 d-flex text-left text-white">
    <div class="container">
      <div class="row text-muted">
        <div class="col-sm-2" valign="top">
          <a href="https://library.upatras.gr/"><img src="https://library.upatras.gr/wp-content/uploads/2018/12/footer_lis_logo.png"></a>
        </div>
        <div class="col-sm-2" valign="top">
          <a href="https://www.upatras.gr/"><img src="https://library.upatras.gr/wp-content/uploads/2017/11/up-logo.png"></a>
        </div>
        <div class="col-sm-6" valign="top">
          <p><strong>Επικοινωνία:</strong> Βιβλιοθήκη &amp; Κέντρο Πληροφόρησης,<br>
          Πανεπιστήμιο Πατρών, Ριο, 26504, Πάτρα.<br>
          Τηλ: 2610 969626 / Ηλεκτρονικό Ταχυδρομείο: <a href="mailto:nemertes@upatras.gr">nemertes@upatras.gr</a></p>
          <p><strong>Contact:</strong> Library &amp; Information Center,<br>
          University of Patras, Rio, 265 04, Patras, Greece.<br>
          Tel. 0030 2610 969626 / Email: <a href="mailto:nemertes@upatras.gr">nemertes@upatras.gr</a></p>
        </div>
        <div class="col-2" valign="top">
          <p><strong>Βοήθεια: </strong> <a href="https://library.upatras.gr/nemertes#support">Για καταθέτες</a></p>
	  <p><a class="social-icon" href="https://twitter.com/nemertesupatras" target="_blank"><img src="https://library.upatras.gr/wp-content/uploads/2018/09/icon_twitter.png" alt="Εικονίδιο Twitter" width="24" height="24"></a></p>
        </div>
       </div>
       <div class="text-muted">
	<div class="float-right credits">
                Developed by UPatras | 
		<a href="https://dhd.gr" target="_blank">Supported by <img src="assets/nemertes/images/DHD_nlogo.png"></a>
	</div>
       </div>
     </div> 
  </div>
</footer>
