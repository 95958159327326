import { Component, EventEmitter, Output } from '@angular/core';
import { HomePageComponent as BaseComponent } from '../../../../app/home-page/home-page.component';
import { Observable, Subscriber, of as observableOf } from 'rxjs';
import { filter, map, startWith, switchMap, take } from 'rxjs/operators';
import { BrowseService } from '../../../../app/core/browse/browse.service';
import { BrowseEntry } from '../../../../app/core/shared/browse-entry.model';
import { Item } from '../../../../app/core/shared/item.model';
import { BrowseEntrySearchOptions } from '../../../../app/core/browse/browse-entry-search-options.model';
import { PaginatedList } from '../../../../app/core/data/paginated-list.model';
import { RemoteData } from '../../../../app/core/data/remote-data';
import { PaginationComponentOptions } from '../../../../app/shared/pagination/pagination-component-options.model';
import { SortOptions, SortDirection } from '../../../../app/core/cache/models/sort-options.model';
import { ActivatedRoute, Router } from '@angular/router';
//import { SearchFilterConfig } from '../../../../app/shared/search/models/search-filter-config.model';
import { SEARCH_CONFIG_SERVICE } from '../../../../app/my-dspace-page/my-dspace-page.component';
import { SearchConfigurationService } from '../../../../app/core/shared/search/search-configuration.service';
import { SearchFilterConfig } from '../../../../app/shared/search/models/search-filter-config.model';
import { FilterType } from '../../../../app/shared/search/models/filter-type.model';
import { getFirstSucceededRemoteData } from '../../../../app/core/shared/operators';
import { SearchService } from '../../../../app/core/shared/search/search.service';
import { isNotEmpty } from '../../../../app/shared/empty.util';
import { ListableObject } from '../../../../app/shared/object-collection/shared/listable-object.model';

@Component({
  selector: 'ds-home-page',
  styleUrls: ['./home-page.component.scss'],
  templateUrl: './home-page.component.html',
  providers: [
    {
      provide: SEARCH_CONFIG_SERVICE,
      useClass: SearchConfigurationService
    }
  ]
})
export class HomePageComponent extends BaseComponent {

  items$: Observable<RemoteData<PaginatedList<Item>>>;
  browseAuthors$: Observable<RemoteData<PaginatedList<BrowseEntry>>>;
  browseSubjects$: Observable<RemoteData<PaginatedList<BrowseEntry>>>;


  filters: Observable<RemoteData<SearchFilterConfig[]>>;
  filterAuthor: SearchFilterConfig = Object.assign(new SearchFilterConfig(), {
    type: {
      value: "discovery-filter"
    },
    _links: {
      self: {
        href: "https://nemertes.library.upatras.gr/server/api/discover/facets/author"
      }
    },
    filterType: FilterType.text,
    name: 'author',
    pageSize: "10"
  });
  filterSubject: SearchFilterConfig = Object.assign(new SearchFilterConfig(), {
    type: {
      value: "discovery-filter"
    },
    _links: {
      self: {
        href: "https://nemertes.library.upatras.gr/server/api/discover/facets/subject"
      }
    },
    filterType: FilterType.text,
    name: 'subject',
    pageSize: "10"
  });
  filterSubmitdate: SearchFilterConfig = Object.assign(new SearchFilterConfig(), {
    type: {
      value: "discovery-filter"
    },
    _links: {
      self: {
        href: "https://nemertes.library.upatras.gr/server/api/discover/facets/dateIssued"
      }
    },
    filterType: FilterType.range,
    name: 'dateIssued',
    pageSize: "5"
  });
  selectedValues$: Observable<string[]>;
  active$: Observable<boolean>;
  authorData: any;
  subjectData: any;
  submitdateData: any;

  searchOptions: BrowseEntrySearchOptions;
  value = '';

  browseauthors = 'author';
  browsesubjects = 'subject';
  browsedateissued = 'dateissued';

  paginationConfig: PaginationComponentOptions = Object.assign(new PaginationComponentOptions(), {
    id: 'bbm',
    currentPage: 1,
    pageSize: 7
  });
  //sortConfig: SortOptions = new SortOptions('score', SortDirection.DESC);
  sortConfig: SortOptions = new SortOptions('dateaccessioned', SortDirection.DESC);
  
  public constructor(
    protected browseService: BrowseService,
    protected activatedRoute: ActivatedRoute,
    private searchConfigService: SearchConfigurationService,
    private searchService: SearchService
  ) { super(activatedRoute); }

  ngOnInit(): void {
    //this.searchOptions = browseParamsToOptions([], this.paginationConfig, this.sortConfig, this.browseauthors);
    //this.browseAuthors$ = this.browseService.getBrowseEntriesFor(this.searchOptions);
    
    //this.searchOptions = browseParamsToOptions([], this.paginationConfig, this.sortConfig, this.browsesubjects);
    //this.browseSubjects$ = this.browseService.getBrowseEntriesFor(this.searchOptions);
    
    //this.initFilters();
    //this.active$ = this.isActive(this.filter, this.data);
    
    this.searchOptions = browseParamsToOptions([], this.paginationConfig, this.sortConfig, this.browsedateissued, true); //true -> fetchthumbnails
    this.items$ = this.browseService.getBrowseItemsFor(this.value, '', this.searchOptions);

    this.selectedValues$ = this.getSelectedValues();

    this.getFilterData(this.filterAuthor).subscribe( (data)=>{
	    this.authorData = data;
    });
    this.getFilterData(this.filterSubject).subscribe( (data)=>{
	    this.subjectData = data;
    });
    this.getFilterData(this.filterSubmitdate).subscribe( (data)=>{
      this.submitdateData = data;
    });

  }

  initFilters() {
    this.filters = this.searchConfigService.getConfig("", "default").pipe(
      getFirstSucceededRemoteData()
    );
  }
  
  // Initialize selectedValues with empty for search url construction in facet options
  private getSelectedValues(): Observable<string[]> {
    return new Observable( (obs) => {
        return obs.next([]);
    });
  
  }

  private isActive(filterOption, dataOut): Observable<boolean> {
          return this.searchConfigService.searchOptions.pipe(
            switchMap((options) => {
                return this.searchService.getFacetValuesFor(filterOption, 1, options).pipe(
                  filter((RD) => !RD.isLoading),
                  map((valuesRD) => { 
                    dataOut = valuesRD.payload;
                    return valuesRD.payload?.totalElements > 0;
                  }),);
              }
            ));
  }

  private getFilterData(filterOption): Observable<any> {
          return this.searchConfigService.searchOptions.pipe(
            switchMap((options) => {
                return this.searchService.getFacetValuesFor(filterOption, 1, options).pipe(
                  filter((RD) => !RD.isLoading),
                  map((valuesRD) => { 
                    return valuesRD.payload;
                  }),);
              }
            ));
  }
  
  
  goPrev() {
    if (this.items$) {
      this.items$.pipe(getFirstSucceededRemoteData()).subscribe((items) => {
        this.items$ = this.browseService.getPrevBrowseItems(items);
      });
    }
  }

  goNext() {
    if (this.items$) {
      this.items$.pipe(getFirstSucceededRemoteData()).subscribe((items) => {
        this.items$ = this.browseService.getNextBrowseItems(items); console.log(this.items$ );
      });
    }
  }

}



/**
 * Function to transform query and url parameters into searchOptions used to fetch browse entries or items
 * @param params            URL and query parameters
 * @param paginationConfig  Pagination configuration
 * @param sortConfig        Sorting configuration
 * @param metadata          Optional metadata definition to fetch browse entries/items for
 */
 export function browseParamsToOptions(params: any,
  paginationConfig: PaginationComponentOptions,
  sortConfig: SortOptions,
  metadata?: string,
  fetchThumbnail?: boolean): BrowseEntrySearchOptions {
  return new BrowseEntrySearchOptions(
    metadata,
    paginationConfig,
    sortConfig,
    +params.startsWith || params.startsWith,
    params.scope,
    fetchThumbnail
  );
 }

