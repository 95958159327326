<div class="background-image">
  <div class="container">
    <div class="jumbotron jumbotron-fluid">
      <div class="d-flex flex-wrap">
	<div class="container">
    	  <div class="jumbotron jumbotron-fluid">
      	    <div class="d-flex flex-wrap">
              <img class="home_image" src="/assets/nemertes/images/nemertes_new_header.png">
 	      <img class="home_image_mobile" src="/assets/nemertes/images/nemertes_new_header_mob.png">
            </div>
          </div>
        </div>
	<div>
          <p>Η "Νημερτής" είναι το Ιδρυματικό Αποθετήριο που φιλοξενεί την πνευματική παραγωγή του Πανεπιστημίου Πατρών. Συγκεντρώνει τις διδακτορικές διατριβές, μεταπτυχιακές και διπλωματικές εργασίες που εκπονούνται στο Πανεπιστήμιο Πατρών και ταυτόχρονα συλλέγει κι άλλο υλικό, όπως είναι τεχνικές αναφορές, δημοσιεύσεις σε περιοδικά και πρακτικά συνεδρίων των μελών ΔΕΠ και των ερευνητών του Πανεπιστημίου, κλπ.</p>
	   <ul>
	     <li><a href="https://library.upatras.gr/nemertes#deposit">Διαβάστε τις οδηγίες για την κατάθεση εργασιών.</a></li>
             <li><a href="https://library.upatras.gr/openaccess#green">Μάθετε πως να κάνετε τις εργασίες σας Ανοικτής Πρόσβασης μέσα από τη "Νημερτή".</a></li>
             <li><a href="https://library.upatras.gr/support/identifiers#orcid">Μάθετε για τα μόνιμα αναγνωριστικά της ORCiD.</a></li>
           </ul> 
        </div>
      </div>
    </div>
  </div>
</div>




