<ds-themed-home-news></ds-themed-home-news>
<div class="container">
  <ng-container *ngIf="(site$ | async) as site">
    <ds-view-tracker [object]="site"></ds-view-tracker>
  </ng-container>
  <ds-search-form [inPlaceSearch]="false" [searchPlaceholder]="'home.search-form.placeholder' | translate"></ds-search-form>

 <div class="row">
  <div class="col-4 home-facet-col">
   <div class="row">
     <div class="communities-col">
        <ds-top-level-community-list></ds-top-level-community-list>
      </div>
   </div>

    <div class="row mt-3">
      <h2>{{'browse.metadata.author' | translate}}</h2>
      <div class="facet-col mt-2">
       <div *ngIf="authorData">
        <ds-search-facet-option *ngFor="let value of authorData.page" [filterConfig]="filterAuthor" [filterValue]="value" [selectedValues$]="selectedValues$" [inPlaceSearch]="false"></ds-search-facet-option>
       </div>
      </div>
    </div>

    <div class="row">
      <h2>{{'browse.metadata.subject' | translate}}</h2>
      <div class="facet-col mt-2">
        <div *ngIf="subjectData">
          <ds-search-facet-option *ngFor="let value of subjectData.page" [filterConfig]="filterSubject" [filterValue]="value" [selectedValues$]="selectedValues$" [inPlaceSearch]="false"></ds-search-facet-option>
        </div>
      </div>
    </div>

    <div class="row">
      <h2>{{'browse.metadata.dateissued' | translate}}</h2>
      <div class="facet-col mt-2">
        <div *ngIf="submitdateData">
	  <ds-search-facet-range-option *ngFor="let value of submitdateData.page" [filterConfig]="filterSubmitdate" [filterValue]="value" [inPlaceSearch]="false"></ds-search-facet-range-option>
        </div>
      </div>
    </div>
  </div>

    <div class="col ml-4 m-0 p-0 items-col">
      <ng-container *ngVar="(items$ | async) as items">
        <h2>{{ 'home.last.submissions' | translate }}</h2>
        <div *ngIf="items?.hasSucceeded && !items?.isLoading && items?.payload?.page.length > 0" >
          <ds-viewable-collection
      		[config]="paginationConfig"
      		[sortConfig]="sortConfig"
      		[showPaginator]="true"
      		[objects]="items"
  		[hideGear]="true"
      		(prev)="goPrev()"
      		(next)="goNext()">
    	 </ds-viewable-collection>
       </div>
       <a href='/search?bbm.page=6&spc.page=1&spc.sf=dc.date.accessioned&spc.sd=DESC' >{{ 'item.truncatable-part.show-more' | translate }}...</a>
      </ng-container>
    </div>
  </div>


  <!--<div *ngIf="active$ | async">
      <ds-search-facet-filter-wrapper
      [filterConfig]="filter"
      [inPlaceSearch]="false">
      </ds-search-facet-filter-wrapper>-->

  <!-- <div class="facet-col">
    <ng-container *ngVar="(browseAuthors$ | async) as objects">     
      <div *ngIf="objects?.hasSucceeded && !objects?.isLoading && objects?.payload?.page.length > 0" @fadeIn>
        <ds-viewable-collection
          [config]="paginationConfig"
          [sortConfig]="sortConfig"
          [showPaginator]="false"
          [hidePaginationDetail]="true"
          [hideGear]="true"
          [objects]="objects">
        </ds-viewable-collection>
      </div>
      <ds-loading *ngIf="!objects || objects?.isLoading" message="{{'loading.browse-by' | translate}}"></ds-loading>
      <ds-error *ngIf="objects?.hasFailed" message="{{'error.browse-by' | translate}}"></ds-error>
      <div *ngIf="!objects?.isLoading && objects?.payload?.page.length === 0">
        <div class="alert alert-info w-100" role="alert">
          {{'browse.empty' | translate}}
        </div>
      </div>
    </ng-container>
  </div>

  <div class="facet-col">   
      <ng-container *ngVar="(browseSubjects$ | async) as objects">     
        <div *ngIf="objects?.hasSucceeded && !objects?.isLoading && objects?.payload?.page.length > 0" @fadeIn>
          <ds-viewable-collection
            [config]="paginationConfig"
            [sortConfig]="sortConfig"
            [showPaginator]="false"
            [hidePaginationDetail]="true"
            [hideGear]="true"
            [objects]="objects">
          </ds-viewable-collection>
        </div>
        <ds-loading *ngIf="!objects || objects?.isLoading" message="{{'loading.browse-by' | translate}}"></ds-loading>
        <ds-error *ngIf="objects?.hasFailed" message="{{'error.browse-by' | translate}}"></ds-error>
        <div *ngIf="!objects?.isLoading && objects?.payload?.page.length === 0">
          <div class="alert alert-info w-100" role="alert">
            {{'browse.empty' | translate}}
          </div>
        </div>
      </ng-container> 
  </div>-->

</div>